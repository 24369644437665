define("ember-event-helpers/helpers/stop-propagation", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stopPropagation = stopPropagation;
  function stopPropagation(_ref) {
    let [handler] = _ref;
    (false && !(!handler || typeof handler === 'function') && (0, _debug.assert)(`Expected '${handler}' to be a function, if present.`, !handler || typeof handler === 'function'));
    return function (event) {
      (false && !(event && typeof event.stopPropagation === 'function') && (0, _debug.assert)(`Expected '${event}' to be an Event and have a 'stopPropagation' method.`, event && typeof event.stopPropagation === 'function'));
      event.stopPropagation();
      if (handler) handler(event);
    };
  }
  var _default = _exports.default = (0, _helper.helper)(stopPropagation);
});